const production = {
  url: {
    API_URL: 'https://www.okclipboard.com/backend'
  }
};

const dev = {
  url: {
    API_URL: 'http://localhost:3050'
  }
};

export const config = process.env.NODE_ENV === 'development' ? dev : production;
