import React from 'react'

function Footer() {
  return (
    <div className='mt-2 flex flex-col items-center'>
      <footer className="p-4 bg-white rounded-lg md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800">
      <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2022 <a href="https://www.snowlinesoftware.com" className="hover:underline">Snow Line Software</a>. All Rights Reserved.
      </span>
      </footer>
    </div>
  );
}

export default Footer;