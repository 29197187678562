import React from 'react'
import { Link } from 'react-router-dom';

const HeroLeft = () => {
  return (
    <div className='md:w-1/2'>
      <h2 className="text-md lg:text-2xl text-gray-600">Simple problem, simple solution</h2>
      <h1 className="text-5xl lg:text-6xl font-bold text-teal-600 mb-2 lg:mb-6">Share snippets of text</h1>
      <p className="text-md lg:text-xl font-light text-gray-800 mb-8">
        Easy and secure access to fragments of text to share between computers.
        Stop sending yourself emails.
      </p>
      <Link to="/signup" className="py-2 px-6 bg-teal-500 hover:bg-teal-600 rounded-md text-white text-lg">Try it out</Link>
    </div>
  );
}

export default HeroLeft;