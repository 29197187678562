import { Disclosure, Transition } from '@headlessui/react';
import { config } from 'Constants';
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import useInterval from 'useIntervalDelay';
import useToken from 'useToken';
import { ChevronUpIcon } from '@heroicons/react/solid';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Checkmark } from 'react-checkmark';

function Snips() {
  const [error, setError] = useState();
  const [data, setData] = useState([]);
  const [lastRead, setLastRead] = useState(Date.now());
  const [updatesAvailable, setUpdatesAvailable] = useState(true);
  const [delay, setDelay] = useState(5000);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const { token } = useToken();
  const [copied, setCopied] = useState(null);
  const [decryptionKeysInput, setDecryptionKeysInput] = useState({});
  const [decryptionKeys, setDecryptionKeys] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchFinalText, setSearchFinalText] = useState("");

  let navigate = useNavigate();

  useEffect(() => {
    let body = {
      token: token,
    };
    if ( searchFinalText.length > 0 ) {
      body["text"] = searchFinalText;
    }
    const url = "/api/" + (searchFinalText.length > 0 ? "search" : "snips");
    fetch(config.url.API_URL + url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => {
      if (!response.ok) {
        setDelay(null); // Stop polling.
        if ( response.status === 401 ) {
          navigate("/signin", { replace: true });
        }
        return response.json().then(json => Promise.reject(json.message));
      }
      return response.json();
    })
    .then(json => {
      setData(json.data);
      setLastRead(Date.now());
      setUpdatesAvailable(false);
    })
    .catch(error => {
      setError(error);
    });
  }, [updatesAvailable, searchFinalText]);

  // Reset the copied flag.
  useEffect(() => {
    const timer = setTimeout(() => setCopied(false), 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [copied]);

  useInterval(() => {
    fetch(config.url.API_URL + "/api/poll", {
      method: 'POST',
      body: JSON.stringify({
        token: token,
        last_update: lastRead
      }),
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => {
      if (!response.ok) {
        setDelay(null); // Stop polling.
        if ( response.status === 401 ) {
          navigate("/signin", { replace: true });
        }
        return response.json().then(json => Promise.reject(json.message));
      }
      return response.json();
    })
    .then(json => {
      setLastRead(Date.now());
      setUpdatesAvailable(json.av);
    })
    .catch(error => {
      setError(error);
    });
  }, autoRefresh ? delay : null);

  const handleAutoRefreshChange = () => {
    setAutoRefresh(!autoRefresh);
  };

  const handleDeleteClicked = (e) => {
    fetch(config.url.API_URL + "/api/snip", {
      method: "DELETE",
      body: JSON.stringify({
        token: token,
        id: parseInt(e.target.id)
      }),
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => {
      if (!response.ok) {
        setDelay(null); // Stop polling.
        if ( response.status === 401 ) {
          navigate("/signin", { replace: true });
        }
        return Promise.resolve();
      }
      const snips = data.filter(snip => snip.id !== e.target.id);
      setUpdatesAvailable(true);
    });
  };

  const handleDecryptClicked = (e) => {
    e.persist();
    setDecryptionKeys((oldDecryptionKeys) => ({
      ...oldDecryptionKeys,
      [e.target.id]: decryptionKeysInput[e.target.id]
    }))
  };

  const handleDecryptionKeyChanged = (e) => {
    e.persist();
    setDecryptionKeysInput((oldDecryptionKeysInput) => ({
      ...oldDecryptionKeysInput,
      [e.target.id]: e.target.value
    }));
  };

  const getSnipContent = (snip) => {
    if ( snip.encrypted ) {
      if ( snip.id in decryptionKeys ) {
        var cryptoJS = require('crypto-js');
        const bytes = cryptoJS.AES.decrypt(snip.content, decryptionKeys[snip.id]);
        return bytes.toString(cryptoJS.enc.Utf8);
      }
    }
    return snip.content;
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  }

  const handleSearchKeyPress = (event) => {
    if ( event.key === 'Enter' ) {
      setSearchFinalText(searchText);
      console.log("Searching " + searchText);
    }
  }

  const snips =
    data.length === 0
    ?
      <div className='mx-auto w-3/4 p-8'>
        <h2 className="text-md lg:text-xl font-light text-gray-800 mb-8">
          No snips found. Create one <Link to="/newsnip">here.</Link>
        </h2>
      </div>
    :
    data.map((snip) => (
    <li key={snip.id} className='mx-auto w-full rounded bg-gray-100 p-2 mt-2'>
      <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="focus:outline-none flex w-full
                                        justify-between px-4 py-1 text-left
                                        text-sm font-medium focus-visible:ring">
            <span>{snip.title}</span>

            <div className='flex'>
              <span className="inline-flex items-center justify-center px-2 py-1 text-xs text-slate-50 font-bold leading-none text-indigo-100 bg-gray-400 rounded mr-4">{snip.created}</span>
              <div className='flex'>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-teal-500`}
                />
              </div>
            </div>
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
            <div className='flex flex-col'>
              <code>
                {getSnipContent(snip)}
              </code>
              <div className='flex justify-start mt-2'>
                <CopyToClipboard text={snip.content} onCopy={() => setCopied(snip.id) }>
                  <button className="bg-teal-500 hover:bg-teal-700 text-white py-1 px-2 rounded w-20">
                  { copied === snip.id ? <Checkmark size="small" color="#223344"/> : "Copy" }
                  </button>
                </CopyToClipboard>
                <button className="ml-2 bg-teal-500 hover:bg-teal-700 text-white py-1 px-2 rounded w-20"
                    onClick={handleDeleteClicked}
                    id={snip.id}>
                  Delete
                </button>
                {snip.encrypted &&
                  <div className='ml-4 flex'>
                    <input type="password" id={snip.id} className='border-2 p-1' placeholder='Decryption key'
                      value={snip.id in decryptionKeysInput ? decryptionKeysInput[snip.id] : ""}
                      onChange={handleDecryptionKeyChanged}
                    />
                    <button className="ml-2 bg-teal-500 hover:bg-teal-700 text-white py-1 px-2 rounded w-20"
                        onClick={handleDecryptClicked}
                        id={snip.id}>
                      Decrypt
                    </button>
                  </div>
                }
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
      </Disclosure>
    </li>
  ));

  const addNewSnipClicked = (e) => {
    navigate("/newsnip", { replace: true });
  }

  return (
    <div className='mx-auto w-3/4 pt-4'>
      <div className="flex justify-between">
        <div className="flex items-center justify-start w-full">
          <label htmlFor="toggleB" className="flex items-center cursor-pointer">
            <div className="relative">
              <input type="checkbox" id="toggleB" className="sr-only"
                checked={autoRefresh}
                onChange={handleAutoRefreshChange}/>
              <div className="block bg-gray-600 w-14 h-8 rounded-full"/>
              <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
            </div>
            <div className="ml-3 text-gray-600 font-medium">
              Auto-refresh
            </div>
          </label>
        </div>

        <div className="flex items-center pr-4">
          <label htmlFor="search" className="flex items-center">
            <div className="text-gray-600 font-medium pr-4">
              Search
            </div>
            <div className="border-2">
              <input type="text" id="search"
                className="m-2 border-none focus:ring-0 focus:outline-none"
                onChange={ handleSearchTextChange }
                onKeyPress={ handleSearchKeyPress }
                />
            </div>
          </label>
        </div>

        <button className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded w-40"
                onClick={addNewSnipClicked}>
          Add new
        </button>
      </div>
      <ul>
        {snips}
      </ul>
    </div>
  )
}

export default Snips;