import React from 'react'
import { Link } from 'react-router-dom'
import useToken from 'useToken';

export default function Nav() {
  const { token } = useToken();

  return (
    <header>
      <nav className="flex flex-row items-center justify-between p-6 container mx-auto">
        <div className="text-lg text-gray-600 lg:flex lg:items-center">
          <Link to="/" className="block mt-4 lg:inline-block text-teal-600 lg:mt-0 mr-10">
            <svg className="h-12 w-12" viewBox="0 0 269 151" version="1.1">
                <title>okclipboard logo</title>
                <defs>
                    <text id="text-1" fontFamily="Verdana-Bold, Verdana" fontSize="166" fontWeight="bold" letterSpacing="7.010355" fill="#13B6A4">
                        <tspan x="129" y="135">K</tspan>
                    </text>
                    <filter x="-3.1%" y="-0.9%" width="107.0%" height="103.8%" filterUnits="objectBoundingBox" id="filter-2">
                        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                        <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                    </filter>
                </defs>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="K" fill="#13B6A4" fillOpacity="1">
                        <use filter="url(#filter-2)" href="#text-1"></use>
                        <use href="#text-1"></use>
                    </g>
                    <g id="Circle-icons-clipboard" transform="translate(10.000000, 10.000000)">
                        <circle id="Oval" fill="#13B6A4" cx="64.5" cy="64.5" r="64.5"></circle>
                        <g id="Group" opacity="0.2" transform="translate(24.000000, 28.000000)" fill="#231F20">
                            <path d="M72.9,0 L8.1,0 C3.645,0 0,3.645 0,8.1 L0,19.2375 L0,72.9 C0,77.355 3.645,81 8.1,81 L72.9,81 C77.355,81 81,77.355 81,72.9 L81,19.2375 L81,8.1 C81,3.645 77.355,0 72.9,0 Z" id="Path"></path>
                        </g>
                        <path d="M96.9,24 L32.1,24 C27.645,24 24,27.645 24,32.1 L24,43.2375 L24,96.9 C24,101.355 27.645,105 32.1,105 L96.9,105 C101.355,105 105,101.355 105,96.9 L105,43.2375 L105,32.1 C105,27.645 101.355,24 96.9,24 Z" id="Path" fill="#E0995E"></path>
                        <g id="Group" opacity="0.2" transform="translate(32.000000, 36.000000)" fill="#231F20">
                            <rect id="Rectangle" x="0" y="0" width="65" height="65"></rect>
                        </g>
                        <rect id="Rectangle" fill="#FFFFFF" x="32" y="32" width="65" height="65"></rect>
                        <rect id="Rectangle" fill="#E0E0D1" x="45" y="48" width="40" height="4"></rect>
                        <rect id="Rectangle" fill="#E0E0D1" x="45" y="56" width="40" height="4"></rect>
                        <rect id="Rectangle" fill="#E0E0D1" x="45" y="65" width="40" height="4"></rect>
                        <rect id="Rectangle" fill="#E0E0D1" x="45" y="73" width="40" height="4"></rect>
                        <rect id="Rectangle" fill="#E0E0D1" x="45" y="81" width="40" height="4"></rect>
                        <g id="Group" opacity="0.2" transform="translate(49.000000, 24.000000)" fill="#231F20">
                            <path d="M26,0 L6,0 L0,0 L0,6 L0,8 L0,10 C0,13.4 2.6,16 6,16 L26,16 C29.4,16 32,13.4 32,10 L32,8 L32,6 L32,0 L26,0 Z" id="Path"></path>
                        </g>
                        <path d="M75,20 L55,20 L49,20 L49,26 L49,28 L49,30 C49,33.4 51.6,36 55,36 L75,36 C78.4,36 81,33.4 81,30 L81,28 L81,26 L81,20 L75,20 Z" id="Path" fill="#4F5D73"></path>
                    </g>
                </g>
            </svg>
          </Link>
          { token &&
            <Link to="/snips" className="mt-4 inline-block text-teal-600 lg:mt-0 mr-10">
              My Snips
            </Link>
          }
          <a href="#" className="mt-4 inline-block hover:text-gray-700 lg:mt-0 mr-10">
            Pricing
          </a>
          <a href="#" className="mt-4 inline-block hover:text-gray-700 lg:mt-0 mr-10">
            About
          </a>
          <a href="#" className="hover:text-gray-700 mt-4 inline-block lg:mt-0">
            Contact
          </a>
        </div>
        <div className="flex items-center">
          <div className="mr-5 lg:mr-0">
            { token && <Link to="/signout" className="py-2 px-6 rounded-md text-gray-600 hover:text-gray-700 text-lg">Sign out</Link> }
            { !token &&
              <div>
                <Link to="/signin" className="py-2 px-6 rounded-md text-gray-600 hover:text-gray-700 text-lg">Sign in</Link>
                <Link to="/signup" className="py-2 px-6 bg-teal-500 hover:bg-teal-600 rounded-md text-white text-lg">Sign up</Link>
              </div>
            }
          </div>
        </div>
      </nav>
    </header>
  )
};
