import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { config } from './Constants';

function SignUp() {
  const [values, setValues] = useState({
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    pass: '',
    confirm: ''
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(config.url.API_URL + "/api/register", {
      method: 'POST',
      body: JSON.stringify(values),
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => {
      if (!response.ok) {
        return response.json().then(json => Promise.reject(json.message));
      }
      setSuccess(true);
    })
    .catch(error => {
      setError(error);
    });
  };
  if ( success ) {
    return (<Navigate to="/"/>);
  }
  const handleUserIdInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      id: event.target.value
    }));
  };
  const handleFirstNameInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      first_name: event.target.value
    }));
  };
  const handleLastNameInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      last_name: event.target.value
    }));
  };
  const handleEmailInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      email: event.target.value
    }));
  };
  const handlePasswordInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      pass: event.target.value
    }));
  };
  const handleConfirmInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      confirm: event.target.value
    }));
  };
  return (
    <form action="#" onSubmit={handleSubmit} className="mt-12 mx-auto w-1/4 bg-gray-100 shadow-sm rounded-md p-8">
      {error && <span id="error-returned-by-server" className='text-red-500 font-bold'>{error}</span>}
      <div className="mb-4">
          <label htmlFor="id" className="mb-3 block text-gray-700">User Id:</label>
          <input type="text" id="id"
            className="bg-white rounded-md border border-gray-200 p-3 focus:outline-none w-full"
            placeholder="johndoe"
            value={values.id}
            onChange={handleUserIdInputChange}
            required/>
      </div>
      <div className='mb-4 flex flex-row justify-between'>
        <div>
            <label htmlFor="firstName" className="mb-3 block text-gray-700">First Name:</label>
            <input type="text" id="firstName"
              className="bg-white rounded-md border border-gray-200 p-3 focus:outline-none w-full"
              placeholder="John"
              value={values.first_name}
              onChange={handleFirstNameInputChange}
              required/>
        </div>
        <div className="ml-2">
            <label htmlFor="lastName" className="mb-3 block text-gray-700">Last Name:</label>
            <input type="text" id="lastName"
              className="bg-white rounded-md border border-gray-200 p-3 focus:outline-none w-full"
              placeholder="Doe"
              value={values.last_name}
              onChange={handleLastNameInputChange}
              required/>
        </div>
      </div>
      <div className="mb-4">
          <label htmlFor="email" className="mb-3 block text-gray-700">Email:</label>
          <input type="email" id="email"
            className="bg-white rounded-md border border-gray-200 p-3 focus:outline-none w-full"
            placeholder="Doe"
            value={values.email}
            onChange={handleEmailInputChange}
            required/>
      </div>
      <div className="mb-4">
          <label htmlFor="password" className="mb-3 block text-gray-700">Password:</label>
          <input type="password" id="password"
            className="bg-white rounded-md border border-gray-200 p-3 focus:outline-none w-full"
            value={values.pass}
            onChange={handlePasswordInputChange}
            required/>
      </div>
      <div className="mb-4">
          <label htmlFor="confirm" className="mb-3 block text-gray-700">Confirm Password:</label>
          <input type="passowrd" id="confirm"
            className="bg-white rounded-md border border-gray-200 p-3 focus:outline-none w-full"
            value={values.confirm}
            onChange={handleConfirmInputChange}
            required/>
      </div>
      { values.pass !== values.confirm
        && <span id="passwords-do-not-match" className='text-red-500 font-bold'>Passwords don't match!</span>}
      <button type="submit" className="py-3 mt-2 px-12 bg-teal-500 hover:bg-teal-600 mr-5 rounded-md text-white text-lg focus:outline-none w-full">Sign up</button>
    </form>
  );
}

export default SignUp;