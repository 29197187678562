import React from 'react'

function Features() {
  return (
    <div className='mt-5 text-md lg:text-lg font-light text-gray-800'>
      <div className='md:flex'>
        <div className='flex flex-col md:w-1/4 items-center p-6'>
          <div className='w-14'>
            <svg version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <mask id="a" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox">
                  <path fill="#FFF" d="M0,0h48v48h-48v-48Z"></path>
                </mask>
              </defs>
                <path className='fill-teal-500' mask="url(#a)" d="M18.02,28h-14.02v4h14.02v6l7.98,-8l-7.98,-8v6Zm11.96,-2v-6h14.02v-4h-14.02v-6l-7.98,8l7.98,8Z" fill="#000"></path>
            </svg>
          </div>
          <div>
            <p>Easily transfer text between computers. No more sending mail to yourself.</p>
          </div>
        </div>
        <div className='flex flex-col md:w-1/4 items-center p-6'>
          <div className='w-14'>
            <svg version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
              <path d="M0,0h48v48h-48Z" fill="none">
              </path>
              <path className='fill-teal-500' fill="#000" d="M44,11.44l-9.19,-7.71l-2.57,3.06l9.19,7.71l2.57,-3.06Zm-28.24,-4.66l-2.57,-3.06l-9.19,7.71l2.57,3.06l9.19,-7.71Zm9.24,9.22h-3v12l9.49,5.71l1.51,-2.47l-8,-4.74v-10.5Zm-1.01,-8c-9.95,0 -17.99,8.06 -17.99,18c0,9.94 8.04,18 17.99,18c9.95,0 18.01,-8.06 18.01,-18c0,-9.94 -8.06,-18 -18.01,-18Zm0.01,32c-7.73,0 -14,-6.27 -14,-14c0,-7.73 6.27,-14 14,-14c7.73,0 14,6.27 14,14c0,7.73 -6.26,14 -14,14Z"></path></svg>
          </div>
          <div className='mt-4'>
            <p>Rich options to automatically expire your snips.</p>
          </div>
        </div>
        <div className='flex flex-col md:w-1/4 items-center p-6'>
          <div className='w-14'>
          <svg version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M0,0h24v24h-24Z" fill="none">
            </path>
            <path className='fill-teal-500' fill="#000" d="M36,16h-2v-4c0,-5.52 -4.48,-10 -10,-10c-5.52,0 -10,4.48 -10,10v4h-2c-2.2,0 -4,1.8 -4,4v20c0,2.2 1.8,4 4,4h24c2.2,0 4,-1.8 4,-4v-20c0,-2.2 -1.8,-4 -4,-4Zm-18.2,-4c0,-3.42 2.78,-6.2 6.2,-6.2c3.42,0 6.2,2.78 6.2,6.2v4h-12.4v-4Zm14.2,20h-6v6h-4v-6h-6v-4h6v-6h4v6h6v4Z"></path><path d="M0,0h48v48h-48Z" fill="none"></path></svg>
          </div>
          <div className='mt-4'>
            <p>Encryption available done on the client for utmost security and privacy.</p>
          </div>
        </div>
        <div className='flex flex-col md:w-1/4 items-center p-6'>
          <div className='w-14'>
          <svg version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
          <path className='fill-teal-500' d="M31,28h-1.59l-0.55,-0.55c1.96,-2.27 3.14,-5.22 3.14,-8.45c0,-7.18 -5.82,-13 -13,-13c-7.18,0 -13,5.82 -13,13c0,7.18 5.82,13 13,13c3.23,0 6.18,-1.18 8.45,-3.13l0.55,0.55v1.58l10,9.98l2.98,-2.98l-9.98,-10Zm-12,0c-4.97,0 -9,-4.03 -9,-9c0,-4.97 4.03,-9 9,-9c4.97,0 9,4.03 9,9c0,4.97 -4.03,9 -9,9Z" fill="#000"></path><path fill="none" d="M0,0h48v48h-48Z"></path></svg>
          </div>
          <div className='mt-4'>
            <p>Search available. Tags for easy classification.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features