import { config } from 'Constants';
import React, { useState } from 'react'
import useToken from 'useToken';
import { useNavigate } from 'react-router-dom';

function NewSnip() {
  const [values, setValues] = useState({
    title: '',
    content: '',
    encryptionKey: ''
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { token } = useToken();
  let navigate = useNavigate();

  const handleTitleInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      title: event.target.value
    }));
  };

  const handleContentTextAreaChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      content: event.target.value
    }));
  };

  const handleEncryptionKeyInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      encryptionKey: event.target.value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const shouldEncrypt = values.encryptionKey.length > 0;
    let content = values.content;
    if ( shouldEncrypt ) {
      var cryptoJS = require('crypto-js');
      content = cryptoJS.AES.encrypt(content, values.encryptionKey).toString();
    }

    fetch(config.url.API_URL + "/api/postsnip", {
      method: 'POST',
      body: JSON.stringify({
        title: values.title,
        content: content,
        encrypted: shouldEncrypt,
        token: token
      }),
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => {
      if (!response.ok) {
        return response.json().then(json => Promise.reject(json.message));
      }
      setSuccess(true);
      navigate("/snips", { replace: true });
    })
    .catch(error => {
      setError(error);
    });
  };

  return (
    <form action="#" onSubmit={handleSubmit} className="mx-auto w-3/4 bg-gray-100 shadow-sm rounded-md p-8">
      {error && <span id="error-returned-by-server" className='text-red-500 font-bold'>{error}</span>}
      <div className="mb-4">
          <label htmlFor="title" className="mb-3 block text-gray-700">Title:</label>
          <input type="text" id="title"
            className="bg-white rounded-md border border-gray-200 p-3 focus:outline-none w-full"
            placeholder="Title of my snip"
            value={values.title}
            onChange={handleTitleInputChange} />
      </div>
      <div className="mb-4">
          <label htmlFor="content" className="mb-3 block text-gray-700">Content:</label>
          <textarea id="email"
            className="bg-white rounded-md border border-gray-200 p-3 focus:outline-none w-full"
            placeholder="Content of my snip"
            value={values.content}
            onChange={handleContentTextAreaChange}
            required/>
      </div>
      <div className="mb-4">
          <label htmlFor="encryptionKey" className="mb-3 block text-gray-700">
            Encryption key (leave empty for no encryption):
          </label>
          <input type="text" id="encryptionKey"
            className="bg-white rounded-md border border-gray-200 p-3 focus:outline-none w-full"
            value={values.encryptionKey}
            onChange={handleEncryptionKeyInputChange} />
      </div>
      <div className="flex flex-col items-center">
        <button type="submit" className="py-3 px-12 bg-teal-500 hover:bg-teal-600 mr-5 rounded-md text-white text-lg focus:outline-none">
          Submit
        </button>
      </div>
    </form>
  );
}

export default NewSnip;