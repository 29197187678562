import React from 'react';
import {
  Navigate,
} from 'react-router-dom';
import useToken from './useToken';

const ProtectedRoute = ({ children }) => {
  const { token } = useToken();

  if ( ! token ) {
    return <Navigate to="/signin" replace />;
  }

  return children;
};

export default ProtectedRoute;