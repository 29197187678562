import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { config } from './Constants';

function SignIn({ setToken }) {
  const [values, setValues] = useState({
    id: '',
    pass: '',
  });

  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(config.url.API_URL + "/api/login", {
      method: 'POST',
      body: JSON.stringify(values),
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => {
      if (!response.ok) {
        return response.json().then(json => Promise.reject(json.message));
      }
      return response.json();
    })
    .then(json => {
      setToken(json.token);
      setSuccess(true);
    })
    .catch(error => {
      setError(error);
    });
  };
  if ( success ) {
    navigate("/snips", { replace: true });
    window.location.reload();
  }

  const handleUserIdInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      id: event.target.value
    }));
  };

  const handlePasswordInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      pass: event.target.value
    }));
  };

  return (
    <form action="#" onSubmit={handleSubmit} className="mx-auto w-1/4 mt-12 bg-gray-100 shadow-sm rounded-md p-8">
      <div className="mb-6">
          <label htmlFor="user" className="mb-3 block text-gray-700">User:</label>
          <input type="text" id="user"
            className="bg-white rounded-md border border-gray-200 p-3 focus:outline-none w-full"
            placeholder="johndoe"
            value={values.id}
            onChange={handleUserIdInputChange}
            required/>
      </div>
      <div className="mb-6">
          <label htmlFor="password" className="mb-3 block text-gray-700">Password:</label>
          <input type="password" id="password"
            className="bg-white rounded-md border border-gray-200 p-3 focus:outline-none w-full"
            value={values.pass}
            onChange={handlePasswordInputChange}
            required/>
      </div>
      <button type="submit"
        className="py-3 px-12 bg-teal-500 hover:bg-teal-600 mr-5 rounded-md text-white text-lg focus:outline-none w-full">Sign in</button>
    </form>
  );
}

SignIn.propTypes = {
  setToken: PropTypes.func.isRequired
}

export default SignIn;