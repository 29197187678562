import Features from 'Features'
import HeroLeft from 'HeroLeft'
import React from 'react'

function Home() {
  return (
    <main className='mt-12 lg:mt-20 flex flex-col'>
      <section className='container mx-auto px-6'>
        <div className="md:flex md:flex-row items-center">
          <HeroLeft/>
        </div>
      </section>
      <section className='container mx-auto px-6'>
        <Features/>
      </section>
    </main>
  )
}

export default Home;