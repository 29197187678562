import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function SignOut() {
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.removeItem('token');
  });

  navigate("/", { replace: true });
  window.location.reload();

  return (
    <div className="flex mt-12 flex-col items-center">
      You are now signed out.
    </div>
  )
}

export default SignOut;