import Nav from './Nav';
import React from 'react';
import './App.css';
import Home from 'Home';
import { Route, Router, Routes } from 'react-router-dom';
import SignIn from 'SignIn';
import SignUp from 'SignUp';
import useToken from 'useToken';
import ProtectedRoute from 'ProtectedRoute';
import NewSnip from './NewSnip';
import Snips from './Snips';
import Footer from 'Footer';
import SignOut from 'SignOut';

function App() {
  const { token, setToken } = useToken();

  return (
    <div className="App bg-gray-20">
      <Nav/>
      <Routes>
        <Route path="signin" element={<SignIn setToken={setToken} />}/>
        <Route path="signup" element={<SignUp />}/>
        <Route path="newsnip" element={<ProtectedRoute><NewSnip/></ProtectedRoute>}/>
        <Route path="snips" element={<ProtectedRoute><Snips/></ProtectedRoute>}/>
        <Route path="signout" element={<ProtectedRoute><SignOut/></ProtectedRoute>}/>
        <Route path="/" element={<Home />}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
